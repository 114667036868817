/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { ToastContainer } from "@react-spectrum/toast";
import { DEFAULT_ASPECT_RATIO } from "@shared/common";
import { CSSProperties, useEffect, useRef, useState } from "react";

import { use3dSceneManager } from "./hooks/use3dSceneManager";
import { CameraControls } from "./tool-components/CameraControls";
import { FrameButton } from "./tool-components/FrameButton";
import { HotSpotEditor } from "./tool-components/HotSpotEditor";
import { HotSpotViewer } from "./tool-components/HotSpotViewer";

import type { SceneManager } from "./scene/SceneManager";
import type { CameraControlsProps } from "./tool-components/CameraControls";
import type { FrameButtonProps } from "./tool-components/FrameButton";
import type { HotSpotEditorProps } from "./tool-components/HotSpotEditor";
import type { AdobeViewerConfiguration } from "@3di/adobe-3d-viewer/dist/config";
import type { CameraOverride, HotSpot, RenderSettings } from "@shared/types";

// Planed but disabled for now
// import Box from "@spectrum-icons/workflow/Box";
// import Light from "@spectrum-icons/workflow/Light";
// import MovieCamera from "@spectrum-icons/workflow/MovieCamera";
// import Resize from "@spectrum-icons/workflow/Resize";
// import Stamp from "@spectrum-icons/workflow/Stamp";

export enum StudioTool {
    cameraControls = "cameraControls",
    frameButton = "frameButton",
    hotSpotsEditor = "hotSpotsEditor",
    hotSpotsViewer = "hotSpotsViewer",
    none = "none",
}

export type StudioStrings = HotSpotEditorProps["strings"] &
    CameraControlsProps["strings"] &
    FrameButtonProps["strings"];

const rightPanelTools: Partial<Record<StudioTool, boolean>> = {
    [StudioTool.hotSpotsEditor]: true,
};

const cameraControlsTools: Partial<Record<StudioTool, boolean>> = {
    [StudioTool.cameraControls]: true,
};

const frameButtonTools: Partial<Record<StudioTool, boolean>> = {
    [StudioTool.frameButton]: true,
};

export interface OptimizerSettings {
    optimizerFps?: number;
    optimizerFreq?: number;
}

export interface SceneEditorProps {
    initialHotSpots?: HotSpot[];
    onHotSpotsUpdate?: (hotSpots: HotSpot[]) => void;
    initialCameraOverride?: CameraOverride;
    onCameraOverrideUpdate?: (cameraOverride: CameraOverride) => void;
    enableLimitedZoom?: boolean;
    modelUrl?: string;
    iblUrl?: string;
    cameraName?: string;
    tools?: StudioTool[];
    strings: StudioStrings;
    aspectRatio?: number;
    renderSettings?: RenderSettings;
    optimizerSettings?: OptimizerSettings;
    onViewLoaded?: () => void;
    onModelLoadError?: () => void;
    setSceneManager?: (sceneManager: SceneManager) => void;
    viewerConfig?: AdobeViewerConfiguration;
}

type ActiveTools = { [key: string]: boolean };

function getOverlayStyles(aspectRatio: string) {
    return {
        aspectRatio,
        pointerEvents: "none" as any,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2,
        height: "100%",
    } as CSSProperties;
}

export function Studio({
    initialHotSpots = [],
    onHotSpotsUpdate,
    initialCameraOverride,
    onCameraOverrideUpdate,
    enableLimitedZoom,
    modelUrl,
    iblUrl,
    cameraName,
    tools = [StudioTool.cameraControls],
    strings,
    aspectRatio = DEFAULT_ASPECT_RATIO,
    renderSettings,
    optimizerSettings,
    onViewLoaded,
    onModelLoadError,
    setSceneManager,
    viewerConfig,
}: SceneEditorProps) {
    const [activeTools, setActiveTools] = useState<ActiveTools>({});
    const viewerRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    const { sceneManager, load3dScene, modelLoaded } = use3dSceneManager(
        viewerRef,
        enableLimitedZoom,
        cameraName,
        renderSettings,
        aspectRatio,
        iblUrl,
        viewerConfig,
        optimizerSettings,
        onModelLoadError,
    );

    useEffect(() => {
        if (sceneManager  && setSceneManager) {
            setSceneManager(sceneManager);
        }
    }, [sceneManager]);

    const hasRightPanelTools = tools.find((tool) => !!rightPanelTools[tool]);
    const hasCameraControlsTools = tools.find(
        (tool) => !!cameraControlsTools[tool],
    );
    const hasFrameButtonTools = tools.find((tool) => !!frameButtonTools[tool]);

    useEffect(() => {
        const toolsUpdate: ActiveTools = {};
        tools.forEach((tool) => {
            toolsUpdate[tool] = true;
        });
        setActiveTools(toolsUpdate);
    }, [tools.join(".")]);

    useEffect(() => {
        if (viewerRef.current && modelUrl && !modelLoaded.current) {
            load3dScene(
                modelUrl,
                initialCameraOverride,
                onCameraOverrideUpdate,
                onViewLoaded,
            );
        }
    }, [viewerRef.current, modelUrl, modelLoaded.current]);

    const overlayStyles = getOverlayStyles(aspectRatio.toString());

    return (
        <View backgroundColor="gray-100">
            {/* Display FPS, for debugging. Update the startOptimizer call with the div id below, and uncomment the
                following div, to display the FPS. Displaying it may slow down the program slightly, though. */}
            {/* <div id="fps">NONE</div> */}
            <ToastContainer />
            <Flex justifyContent="center" height="100%">
                <View position="relative" width="100%" height="100%">
                    {activeTools[StudioTool.hotSpotsViewer] &&
                        !activeTools[StudioTool.hotSpotsEditor] && (
                            <HotSpotViewer
                                overlayRef={overlayRef}
                                sceneManager={sceneManager}
                                hotSpots={initialHotSpots}
                            />
                        )}
                    <div
                        data-uia="scene-overlay-portal"
                        style={overlayStyles}
                        ref={overlayRef}
                    />
                    <div data-uia="scene-overlay-tools" style={{
                        ...overlayStyles,
                        opacity: !!sceneManager ? "1" : "0",
                        transition: "opacity 1s"
                    }}>
                        <Flex position="relative" height="100%" width="100%">
                            <Flex
                                direction="column"
                                justifyContent="end"
                                gap="size-125"
                                margin="size-150">
                                {hasCameraControlsTools && (
                                    <CameraControls
                                        sceneManager={sceneManager}
                                        strings={{
                                            cameraDolly: strings.cameraDolly,
                                            cameraOrbit: strings.cameraOrbit,
                                            cameraPan: strings.cameraPan,
                                        }}
                                    />
                                )}
                            </Flex>
                            <div style={{ flexGrow: 1 }} />
                            <Flex
                                direction="column"
                                justifyContent="end"
                                margin="size-150">
                                {hasFrameButtonTools && (
                                    <FrameButton
                                        sceneManager={sceneManager}
                                        aspectRatio={aspectRatio}
                                        strings={{
                                            frameButton: strings.frameButton,
                                        }}
                                    />
                                )}
                            </Flex>
                        </Flex>
                    </div>
                    <div
                        onFocus={() => sceneManager?.attachWindowListeners()}
                        onBlur={() => sceneManager?.detachWindowListeners()}
                        data-uia="scene-viewer"
                        style={{
                            ...overlayStyles,
                            pointerEvents: "all",
                            zIndex: 1,
                        }}
                        ref={viewerRef}
                    />
                </View>
                <View padding={hasRightPanelTools ? "size-150" : 0}>
                    <Flex direction="column" gap="size-125">
                        {/* Right tools here */}
                        {/*
                            Planed but disabled for now

                            <Button variant="secondary">
                                <Light />
                            </Button>
                            <Button variant="secondary">
                                <Box />
                            </Button>
                            <Button variant="secondary">
                                <MovieCamera />
                            </Button>
                            <Button variant="secondary">
                                <Stamp />
                            </Button>
                            <Button variant="secondary">
                                <Resize />
                            </Button>

                            */}
                        {activeTools[StudioTool.hotSpotsEditor] && (
                            <HotSpotEditor
                                overlayRef={overlayRef}
                                sceneManager={sceneManager}
                                strings={strings}
                                initialHotSpots={initialHotSpots}
                                onHotSpotsUpdate={onHotSpotsUpdate}
                            />
                        )}
                    </Flex>
                </View>
            </Flex>
        </View>
    );
}
