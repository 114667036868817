import { v5 as uuidv5 } from "uuid";

import type { FileAccessInfo } from "../types/services.js";

import {
    MediaTypesImage,
    MediaTypesMaterial,
    MediaTypesModel,
} from "../index.js";

import {
    THUMBNAIL_WIDTH,
} from "./index.js";

import type {
    ImageMediaExtensions,
    MaterialMediaExtensions,
    ModelMediaExtensions,
    MultiJobRequest,
    MultiNode,
    MultiNodes,
    NodeId,
    RenderQualityOption,
    ResizeOptions,
    SceneMediaExtensions,
} from "@shared/types";

const UUID_NAMESPACE = "d6c79d0d-56ec-42b1-9ff2-fd099397b9c9";


export type UploadTypes =
    | "https.upload"
    | "https.acp.asset.upload"
    | "https.acp.component.upload"
    | "aero.publish";

export type FileVariationKeys =
    | "background"
    | "model"
    | "texture"
    | "decal"
    | "material";

export type ColorVariationKeys = "color";

export type VariationKeys = FileVariationKeys | ColorVariationKeys;

export type ReplacementTarget = FileReplacementTarget | ColorReplacementTarget;

export type FileReplacementTarget = {
    id: string;
    name: string;
    path: string;
    type: FileVariationKeys;
    variations: FileVariation[];
    isDependentOn?: string;
};

export type ColorReplacementTarget =
    | {
          id: string;
          path: string;
          name: string;
          type: ColorVariationKeys;
          variations: ColorVariation[];
          materialType: "mdl";
          colorType: "rgba";
          isDependentOn?: string;
      }
    | {
          id: string;
          path: string;
          name: string;
          type: ColorVariationKeys;
          variations: ColorVariation[];
          materialType: "sbsar";
          colorChannel: string;
          colorChannelName: string;
          colorType: "rgb";
          isDependentOn?: string;
      };

export type CameraState = {
    id: string;
    path: string;
    name: string;
    selected?: boolean;
    width: number;
    height: number;
};

export function createJobsFromConfigurations(
    uploadNodeType: UploadTypes,
    templateFileGetUrl: string,
    templateFileExtension: string,
    targets: ReplacementTarget[],
    cameras: CameraState[],
    sizeMultiplier: number,
    quality?: RenderQualityOption,
) {
    const rootSpec: MultiNodes = {};
    const [rootNodeId, rootNode] = makeKey({
        type: "https.download",
        parameters: {
            uri: templateFileGetUrl,
        },
        output: {
            type: "scene",
            extension: templateFileExtension as SceneMediaExtensions,
        },
    });
    rootSpec[rootNodeId] = rootNode;

    targets.sort(sortByType);
    const validTargets = targets.filter(
        (target) => target.variations.length > 0,
    );

    const variations: Array<Set<VariationTemplate>> = [];

    let x: Set<VariationTemplate>;
    for (const target of validTargets) {
        x = new Set();
        switch (target.type) {
            case "background":
                for (const variation of target.variations) {
                    x.add(
                        (nodes, tailId) =>
                            new SetBackgroundTexture(tailId, variation),
                    );
                }
                break;
            case "texture":
                for (const variation of target.variations) {
                    x.add(
                        (nodes, tailId) =>
                            new SetDecalOrMaterialTexture(
                                tailId,
                                variation,
                                target.path,
                            ),
                    );
                }
                break;
            case "color":
                for (const variation of target.variations) {
                    if (variation.matType === "mdl") {
                        x.add(
                            (nodes, tailId) =>
                                new SetMaterialColor(
                                    tailId,
                                    variation,
                                    target.path,
                                ),
                        );
                    } else if (target.materialType === "sbsar") {
                        x.add(
                            (nodes, tailId) =>
                                new SetSubstanceMaterialColor(
                                    tailId,
                                    variation,
                                    target.path,
                                    target.colorChannel,
                                ),
                        );
                    }
                }
                break;
            case "decal":
                for (const variation of target.variations) {
                    x.add(
                        (nodes, tailId) =>
                            new SetDecalOrMaterialTexture(
                                tailId,
                                variation,
                                target.path,
                            ),
                    );
                }
                break;
            case "model":
                for (const variation of target.variations) {
                    x.add(
                        (nodes, tailId) =>
                            new ReplaceModel(tailId, variation, target.path),
                    );
                }
                break;
            case "material":
                for (const variation of target.variations) {
                    x.add(
                        (nodes, tailId) =>
                            new ReplaceMaterial(tailId, variation, target.path),
                    );
                }
                break;
        }
        variations.push(x);
    }

    x = new Set();
    for (const camera of cameras) {
        if (camera.selected) {
            x.add(
                (nodes, tailId) =>
                    new Render(
                        uploadNodeType,
                        tailId,
                        camera.path,
                        Math.round(camera.width * sizeMultiplier),
                        Math.round(camera.height * sizeMultiplier),
                        quality,
                    ),
            );
        }
    }
    variations.push(x);

    // Produce all combinations
    const jobProtos: Array<Array<VariationTemplate>> = [];
    const cartesianProduct = (acc: Array<VariationTemplate>, idx: number) => {
        if (idx >= variations.length) {
            jobProtos.push(acc);
        } else {
            for (const elem of variations[idx]) {
                cartesianProduct(acc.concat(elem), idx + 1);
            }
        }
    };
    cartesianProduct([], 0);

    const finalizedJobSpecs: MultiJobRequest[] = [];

    // Run factory methods to actually produce jobs
    for (const jobProto of jobProtos) {
        // Move next line  outside of for loop to produce 1 big job
        const spec: MultiNodes = JSON.parse(JSON.stringify(rootSpec));
        let tailId = rootNodeId;
        for (const nodesProto of jobProto) {
            const variation = nodesProto(spec, tailId);
            tailId = variation.appendOpMut(spec);
        }

        // append thumbnail render
        Object.keys(spec).find((nodeId) => {
            if (spec[nodeId].type === "scene.render") {
                const thumbnail = new Resize(uploadNodeType, nodeId, {
                    width: THUMBNAIL_WIDTH,
                });
                thumbnail.appendOpMut(spec);
            }
        });

        // append an exportGLB
        const exportGlb = new ExportGLB(uploadNodeType, tailId);
        exportGlb.appendOpMut(spec);
        finalizedJobSpecs.push({
            type: "multi.beta",
            spec,
        });
    }

    return finalizedJobSpecs;
}

export abstract class Variation {
    nodes: MultiNodes;
    tailId: NodeId;
    opId: NodeId;
    constructor(nodes: MultiNodes, tailId: NodeId, opId: NodeId) {
        this.nodes = nodes;
        this.tailId = tailId;
        this.opId = opId;
    }

    appendOpMut(nodes: MultiNodes): NodeId {
        Object.assign(nodes, this.nodes);
        return this.tailId;
    }
}

function makeUploadNodeTemplate(
    uploadNodeType: UploadTypes,
    inputId: NodeId,
): MultiNode {
    const inputs = { file: inputId };
    if (uploadNodeType === "https.upload") {
        return {
            type: uploadNodeType,
            inputs,
            parameters: {
                uri: "",
            },
        };
    } else throw new Error("Unhandled upload node type");
}

function cleanMaterialExtension(extn: string) {
    if (
        extn &&
        MediaTypesMaterial.indexOf(extn as MaterialMediaExtensions) > -1
    ) {
        return extn as MaterialMediaExtensions;
    }
    throw new Error("Failed to get valid extension for a material");
}

function cleanMediaExtension(extn: string) {
    if (extn && MediaTypesImage.indexOf(extn as ImageMediaExtensions) > -1) {
        return extn as ImageMediaExtensions;
    }
    throw new Error("Failed to get valid extension for an image");
}

function cleanModelExtension(extn: string) {
    if (extn && MediaTypesModel.indexOf(extn as ModelMediaExtensions) > -1) {
        return extn as ModelMediaExtensions;
    }
    throw new Error(`Failed to get valid extension for a model: ${extn}`);
}

export class SetBackgroundTexture extends Variation {
    constructor(tailId: NodeId, variation: FileVariation) {
        const [downloadId, downloadNode] = makeKey({
            type: "https.download",
            output: {
                type: "image",
                extension: cleanMediaExtension(variation.fileExtension),
            },
            parameters: {
                uri: variation.fileAccess.getUrl,
            },
        });

        const [opId, opNode] = makeKey({
            type: "scene.setBackgroundTexture",
            output: {
                type: "scene",
                extension: ".ssg",
            },
            inputs: {
                scene: tailId,
                image: downloadId,
            },
        });
        const nodes: MultiNodes = {};
        nodes[downloadId] = downloadNode;
        nodes[opId] = opNode;
        super(nodes, opId, opId);
    }
}

export class SetDecalOrMaterialTexture extends Variation {
    constructor(tailId: NodeId, variation: FileVariation, materialId: string) {
        const [downloadId, downloadNode] = makeKey({
            type: "https.download",
            output: {
                type: "image",
                extension: cleanMediaExtension(variation.fileExtension),
            },
            parameters: {
                uri: variation.fileAccess.getUrl,
            },
        });

        const [opId, opNode] = makeKey({
            type: "scene.setDecalOrMaterialTexture",
            output: {
                type: "scene",
                extension: ".ssg",
            },
            inputs: {
                scene: tailId,
                image: downloadId,
            },
            parameters: {
                decalOrMaterialId: materialId,
            },
        });

        const nodes: MultiNodes = {};
        nodes[downloadId] = downloadNode;
        nodes[opId] = opNode;
        super(nodes, opId, opId);
    }
}

export class ReplaceMaterial extends Variation {
    constructor(tailId: NodeId, variation: FileVariation, materialId: string) {
        const [downloadId, downloadNode] = makeKey({
            type: "https.download",
            output: {
                type: "material",
                extension: cleanMaterialExtension(variation.fileExtension),
            },
            parameters: {
                uri: variation.fileAccess.getUrl,
            },
        });

        const [opId, opNode] = makeKey({
            type: "scene.replaceMaterial",
            output: {
                type: "scene",
                extension: ".ssg",
            },
            inputs: {
                scene: tailId,
                material: downloadId,
            },
            parameters: {
                materialId: materialId,
            },
        });

        const nodes: MultiNodes = {};
        nodes[downloadId] = downloadNode;
        nodes[opId] = opNode;
        super(nodes, opId, opId);
    }
}
export class SetSubstanceMaterialColor extends Variation {
    constructor(
        tailId: NodeId,
        variation: ColorVariation,
        materialId: string,
        property: string,
    ) {
        const [opId, opNode] = makeKey({
            type: "scene.setProperty",
            output: {
                type: "scene",
                extension: ".ssg",
            },
            inputs: {
                scene: tailId,
            },
            parameters: {
                nodeId: materialId,
                property,
                value: variation.color as any,
            },
        });

        const nodes: MultiNodes = {};
        nodes[opId] = opNode;
        super(nodes, opId, opId);
    }
}
export class SetMaterialColor extends Variation {
    constructor(tailId: NodeId, variation: ColorVariation, materialId: string) {
        const { color } = variation;

        const [opId, opNode] = makeKey({
            type: "scene.setDecalOrMaterialColor",
            output: {
                type: "scene",
                extension: ".ssg",
            },
            inputs: {
                scene: tailId,
            },
            parameters: {
                decalOrMaterialId: materialId,
                color: {
                    red: color[0],
                    green: color[1],
                    blue: color[2],
                    alpha: color[3],
                },
            },
        });

        const nodes: MultiNodes = {};
        nodes[opId] = opNode;
        super(nodes, opId, opId);
    }
}

export class ReplaceModel extends Variation {
    constructor(tailId: NodeId, variation: FileVariation, modelId: string) {
        const [downloadId, downloadNode] = makeKey({
            type: "https.download",
            output: {
                type: "model",
                extension: cleanModelExtension(variation.fileExtension),
            },
            parameters: {
                uri: variation.fileAccess.getUrl,
            },
        });

        const [opId, opNode] = makeKey({
            type: "scene.replaceModel",
            output: {
                type: "scene",
                extension: ".ssg",
            },
            inputs: {
                scene: tailId,
                model: downloadId,
            },
            parameters: {
                modelId,
            },
        });

        const nodes: MultiNodes = {};
        nodes[downloadId] = downloadNode;
        nodes[opId] = opNode;
        super(nodes, opId, opId);
    }
}

export class Render extends Variation {
    constructor(
        uploadNodeType: UploadTypes,
        tailId: NodeId,
        cameraId: string,
        width: number,
        height?: number,
        quality?: RenderQualityOption,
    ) {
        const [renderId, renderNode] = makeKey({
            type: "scene.render",
            output: {
                type: "image",
                extension: ".png",
            },
            inputs: {
                scene: tailId,
            },
            parameters: {
                cameraId,
                height,
                width,
                quality,
            },
        });

        const [uploadId, uploadNode] = makeKey(
            makeUploadNodeTemplate(uploadNodeType, renderId),
        );
        const nodes: MultiNodes = {};
        nodes[renderId] = renderNode;
        nodes[uploadId] = uploadNode;
        super(nodes, tailId, renderId);
    }
}

export class Resize extends Variation {
    constructor(
        uploadNodeType: UploadTypes,
        tailId: NodeId,
        resizeOptions: ResizeOptions,
    ) {
        const [renderId, renderNode] = makeKey({
            type: "image.resize",
            output: {
                type: "image",
                extension: ".png",
            },
            inputs: {
                file: tailId,
            },
            parameters: resizeOptions,
        });

        const [uploadId, uploadNode] = makeKey(
            makeUploadNodeTemplate(uploadNodeType, renderId),
        );
        const nodes: MultiNodes = {};
        nodes[renderId] = renderNode;
        nodes[uploadId] = uploadNode;
        super(nodes, tailId, renderId);
    }
}

export class ExportGLB extends Variation {
    constructor(uploadNodeType: UploadTypes, tailId: NodeId) {
        const [exportId, exportNode] = makeKey({
            type: "scene.export",
            inputs: {
                scene: tailId,
            },
            output: {
                type: "model",
                extension: ".glb",
            },
        });

        const [uploadId, uploadNode] = makeKey(
            makeUploadNodeTemplate(uploadNodeType, exportId),
        );
        const nodes: MultiNodes = {};
        nodes[exportId] = exportNode;
        nodes[uploadId] = uploadNode;
        super(nodes, tailId, exportId);
    }
}

export interface VariationTemplate {
    (nodes: MultiNodes, tailId: NodeId): Variation;
}

export type FileVariation = {
    md5: string;
    fileName: string;
    fileExtension: string;
    fileSize: number;
    metadata?: any;
    fileAccess: FileAccessInfo;
};
export type ColorVariation = {
    path: string;
    matType: "mdl" | "sbsar";
    color: number[];
};

const targetTypeToValueMap: { [K in ReplacementTarget["type"]]: number } = {
    model: 5,
    material: 4,
    texture: 3,
    color: 2,
    decal: 1,
    background: 0,
};

function sortByType(a: ReplacementTarget, b: ReplacementTarget) {
    return targetTypeToValueMap[b.type] - targetTypeToValueMap[a.type];
}

export function makeKey(obj: MultiNode): [NodeId, MultiNode] {
    return [uuidv5(JSON.stringify(obj), UUID_NAMESPACE), obj];
}

